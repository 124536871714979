export default {
  ApiLink: 'process.env.VUE_APP_BASEURL',

}
export const BASE_URL = 'process.env.VUE_APP_BASEURL'
// Dashboard
export const DASHBOARD_ISP = '/api/isp/dashboard/summery'
// Router API Manage
export const ROUTER_LIST = '/api/isp/router/index'
export const ROUTER_CREATE = '/api/isp/router/store'
export const ROUTER_UPDATE = '/api/isp/router/update/'
export const ROUTER_VIEW = '/api/isp/router/show/'

// Package API Manage
export const PACKAGE_LIST = '/api/isp/package/index'
export const PACKAGE_CREATE = '/api/isp/package/store'
export const PACKAGE_VIEW = '/api/isp/package/show/'
export const PACKAGE_UPDATE = '/api/isp/package/update/'
// Customer UserName API Manage
export const USERNAME_LIST = '/api/isp/isp-user/index'
export const USERNAME_CREATE = '/api/isp/isp-user/store'
export const USERNAME_VIEW = '/api/isp/isp-user/show/'
export const USERNAME_VIEW_V2 = '/api/isp/isp-user/show-v2/'
export const LIVE_DATA = '/api/get-live-data/'
export const STATIC_DATA = '/api/router-static-data-isp/'
export const USERNAME_UPDATE = '/api/isp/isp-user/update/'
export const PAIR_CODE_FIND = '/api/isp/isp-user/find-pair-code?'
export const USER_MOBILE_FIND = '/api/isp/isp-user/find-user-mobile-number?'
export const USER_CHANGE_PASSWORD_ISP = '/api/isp/isp-user/change-password'
export const NOT_BILLING = '/api/get-not-billing-users/'
export const NOT_ROUTER = '/api/get-not-router-users/'
export const OFFLINE_USERS = '/api/get-offline-users/'

export const USERNAME_DELETE = '/api/isp/isp-user/delete-username'
//
// Mobile Banking Info
export const MOBILE_BAKING_INFO = '/api/isp/payment/mobile-banking-info'
export const MOBILE_BAKING_INFO_UPDATE = '/api/isp/payment/mobile-banking-info-update'
// Isp Info
export const ISP_PROFILE_INFO = '/api/isp/isp-info/show'
export const ISP_PROFILE_UPDATE = '/api/isp/isp-info/profile-update'
export const ISP_CHANGE_PASSWORD = '/api/isp/change-password'

// Common Resources
export const COMPANY_SETTING = '/api/auth/company-setting'
export const CUSTOMER_USER_LIST = '/api/all-user-list'
export const ISP_PACKAGE_LIST = '/api/isp/package/isp-package-list'
export const ISP_ROUTER_LIST = '/api/isp/router/isp-router-list'
export const ISP_LICENCE_LIST = '/api/isp-licence-list'
export const ISP_LIST = '/api/get-all-isp-list'
export const TEST = '/api/test'

export const DIVISION_LIST = '/api/division-list'
export const DISTRICT_LIST = '/api/district-list/'
export const UPAZILA_LIST = '/api/upazila-list/'
export const THANA_LIST = '/api/thana-list/'
export const INVOICE_LIST = '/api/isp/invoice/list'
export const INVOICE_SHOW = '/api/isp/invoice/show-invoice'
export const INVOICE_EDIT = '/api/isp/invoice/invoice-edit'
export const INVOICE_PAYMENT = '/api/isp/invoice/invoice-payment'
export const BULK_INVOICE_PAID = '/api/isp/invoice/bulk-invoice-paid'
export const BULK_INVOICE_UNPAID = '/api/isp/invoice/bulk-invoice-unpaid'
export const INVOICE_CREATE = '/api/isp/invoice/create/'
export const INVOICE_DELETE = '/api/isp/invoice/delete-invoice'

// Report
export const DATE_WISE_REPORT = '/api/isp/report/date-wise-report'
export const NEW_CONNECTION_REPORT = '/api/isp/report/new-connection-report'
export const DATE_WISE_REPORT_NEW = '/api/date-wise-report'
export const UN_INVOICED_REPORT = '/api/isp/report/un-invoiced-report'
export const MONTH_WISE_REPORT = '/api/isp/report/month-wise-report'
export const DUPLICATE_DEVICES = '/api/isp/report/duplicate-devices'

// Settlement
export const NEW_SETTLEMENT = '/api/admin/settlement/create-new'
export const SETTLEMENT_LIST = '/api/admin/settlement/list'
export const ADMIN_SETTLEMENT_LIST = '/api/admin/settlement/settlement-list'
export const SETTLEMENT_DETAILS = '/api/admin/settlement/details/'
export const SETTLEMENT_UPDATE = '/api/admin/settlement/update'
export const ADMIN_ISP_SETTLEMENT_LIST = '/api/admin/settlement/isp-settlement'
// ISP Settlement
export const UN_SETTLEMENT_LIST = '/api/isp/settlement/un-settlement/list'

// Isp Management
export const ADMIN_ISP_LIST = '/api/admin/isp-manage/all-isp-list'
export const ADMIN_ISP_PPP_LIST = '/api/admin/isp-manage/all-isp-ppp-list'
export const ADMIN_ISP_UNASSIGN_PPP_LIST = '/api/admin/isp-manage/unassign-isp-ppp-list'
// User Management
// Permission
export const ADMIN_PERMISSION_LIST = '/api/permission/list'
export const ADMIN_PERMISSION_STORE = '/api/permission/store'
export const ADMIN_PERMISSION_UPDATE = '/api/permission/update/'
export const ADMIN_PERMISSION_DELETE = '/api/permission/delete/'
// role
export const ADMIN_ROLE_LIST = '/api/role/list'
export const ADMIN_ROLE_STORE = '/api/role/store'
export const ADMIN_ROLE_UPDATE = '/api/role/update/'
export const ADMIN_ROLE_DELETE = '/api/role/delete/'
export const ADMIN_ROLE_SHOW = '/api/role/edit/'
export const ADMIN_ROLELIST = '/api/role/role-list'

// Users
export const ADMIN_USER_LIST = '/api/user/list'
export const ADMIN_USER_STORE = '/api/user/store'
export const ADMIN_USER_UPDATE = '/api/user/update/'
export const ADMIN_USER_DELETE = '/api/user/delete/'
export const ADMIN_USER_EDIT = '/api/user/edit/'

// Isp Services Package Payment
export const SERVICES_PACKAGE_LIST = '/api/isp/services-package/all-services-package'
export const SERVICES_PACKAGE_PAY = '/api/isp/services-package/pay-services-package-active'
export const SERVICES_PAYMENT_HISTORY = '/api/isp/services-package/services-payment-history'
export const SERVICES_PAYMENT_STATUS = '/api/isp/services-package/monthly-payment-status'

// Admin Service Package List CRUD
export const ADMIN_SERVICES_PACKAGE_DETAIL = '/api/admin/isp-manage/service-package-detail/'
export const ADMIN_SERVICES_PACKAGE_LIST = '/api/admin/isp-manage/service-package-list'
export const ADMIN_SERVICES_PACKAGE_CREATE_UPDATE = '/api/admin/isp-manage/service-package-store-update'

// Admin Coupon Code CRUD
export const ADMIN_COUPON_CODE_LIST = '/api/admin/coupon-code/list'
export const ADMIN_COUPON_CODE_CREATE_UPDATE = '/api/admin/coupon-code/store-update'
export const ADMIN_COUPON_CODE_DETAIL = '/api/admin/coupon-code/details/'

// Payment Info
export const PAYMENT_DETAILS = '/api/payment/status/get-payment-details'
export const COUPON_CODE_CHECK = '/api/isp/check-coupon-code'
// export const TEST = "/api/isp/test"

// Reseller Routes
export const RESELLER_CONDITION = '/api/isp/reseller/reseller-condition'
export const SEARCH_USERS = '/api/search-users'

// Notification Routes
export const SEND_NOTIFICATION = '/api/send-notification'

// Ticket Routes

export const GET_TICKETS = '/api/isp/ticket/tickets'
export const CREATE_TICKET = '/api/isp/ticket/create-ticket'
export const UPDATE_TICKET = '/api/isp/ticket/update-ticket'
export const DELETE_TICKET = '/api/isp/ticket/delete-ticket'
export const UPDATE_TICKET_STATUS = '/api/isp/ticket/update-ticket-status'
export const UPDATE_TICKET_DEPARTMENT = '/api/isp/ticket/update-ticket-department'
export const UPDATE_TICKET_URGENCY = '/api/isp/ticket/update-ticket-urgency'
export const UPDATE_TICKET_STAFF = '/api/isp/ticket/update-ticket-staff'
export const GET_TICKET_DETAILS = '/api/isp/ticket/get-ticket-details'
export const ADD_TICKET_REPLY = '/api/isp/ticket/add-ticket-reply'
export const GET_TICKET_REPLIES = '/api/isp/ticket/get-ticket-replies'

export const GET_DEPARTMENTS = '/api/isp/ticket/departments'
export const ADD_DEPARTMENT = '/api/isp/ticket/add-department'
export const UPDATE_DEPARTMENT = '/api/isp/ticket/update-department'
export const DELETE_DEPARTMENT = '/api/isp/ticket/delete-department'

export const GET_STAFFS = '/api/isp/ticket/staffs'
export const ADD_STAFF = '/api/isp/ticket/add-staff'
export const UPDATE_STAFF = '/api/isp/ticket/update-staff'
export const DELETE_STAFF = '/api/isp/ticket/delete-staff'

//

export const PPP_PROFILES = '/api/get-ppp-profiles'
