export default {
  // Endpoints
  loginEndpoint: '/api/isp/login',
  registerEndpoint: '/api/isp/register',
  ispRegistrationPayEndpoint: '/api/isp/isp-registration',
  refreshEndpoint: '/api/isp/refresh-token',
  logoutEndpoint: '/api/isp/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
