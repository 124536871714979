import jwtDefaultConfig from './jwtDefaultConfig'
import {
    ISP_CHANGE_PASSWORD,
    ISP_PROFILE_UPDATE,
    MOBILE_BAKING_INFO_UPDATE,
    PACKAGE_CREATE,
    PACKAGE_UPDATE,
    ROUTER_CREATE,
    ROUTER_UPDATE,
    USERNAME_CREATE,
    USERNAME_UPDATE,
    USERNAME_DELETE,
    INVOICE_EDIT,
    INVOICE_PAYMENT,
    INVOICE_CREATE,
    INVOICE_DELETE,
    NEW_SETTLEMENT,
    SETTLEMENT_LIST,
    SETTLEMENT_UPDATE,
    ADMIN_PERMISSION_STORE,
    ADMIN_ROLE_STORE,
    ADMIN_ROLE_UPDATE,
    ADMIN_USER_STORE,
    USER_CHANGE_PASSWORD_ISP,
    BULK_INVOICE_PAID,
    BULK_INVOICE_UNPAID, SERVICES_PACKAGE_PAY, ADMIN_SERVICES_PACKAGE_CREATE_UPDATE, ADMIN_COUPON_CODE_CREATE_UPDATE,
} from '../../services/api'

export default class JwtService {
    // Will be used by this service for making API calls
    axiosIns = null

    // jwtConfig <= Will be used by this service
    jwtConfig = {...jwtDefaultConfig }

    // For Refreshing Token
    isAlreadyFetchingAccessToken = false

    // For Refreshing Token
    subscribers = []

    constructor(axiosIns, jwtOverrideConfig) {
        this.axiosIns = axiosIns
        this.jwtConfig = {...this.jwtConfig, ...jwtOverrideConfig }

        // Request Interceptor
        this.axiosIns.interceptors.request.use(
            config => {
                // Get token from localStorage
                const accessToken = this.getToken()

                // If token is present add it to request's Authorization Header
                if (accessToken) {
                    // eslint-disable-next-line no-param-reassign
                    config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                }
                return config
            },
            error => Promise.reject(error),
        )

        // Add request/response interceptor
        this.axiosIns.interceptors.response.use(
            response => response,
            error => {
                // const { config, response: { status } } = error
                const { config, response } = error
                const originalRequest = config

                // if (status === 401) {
                if (response && response.status === 401) {
                    if (!this.isAlreadyFetchingAccessToken) {
                        this.isAlreadyFetchingAccessToken = true
                        this.refreshToken().then(r => {
                            this.isAlreadyFetchingAccessToken = false

                            // Update accessToken in localStorage
                            this.setToken(r.data.accessToken)
                            this.setRefreshToken(r.data.refreshToken)

                            this.onAccessTokenFetched(r.data.accessToken)
                        })
                    }
                    const retryOriginalRequest = new Promise(resolve => {
                        this.addSubscriber(accessToken => {
                            // Make sure to assign accessToken according to your response.
                            // Check: https://pixinvent.ticksy.com/ticket/2413870
                            // Change Authorization header
                            originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                            resolve(this.axiosIns(originalRequest))
                        })
                    })
                    return retryOriginalRequest
                }
                return Promise.reject(error)
            },
        )
    }

    onAccessTokenFetched(accessToken) {
        this.subscribers = this.subscribers.filter(callback => callback(accessToken))
    }

    addSubscriber(callback) {
        this.subscribers.push(callback)
    }

    getToken() {
        return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
    }

    getRefreshToken() {
        return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
    }

    setToken(value) {
        localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
    }

    setRefreshToken(value) {
        localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
    }

    login(...args) {
        return this.axiosIns.post(process.env.VUE_APP_BASEURL + this.jwtConfig.loginEndpoint, ...args)
    }

    register(...args) {
        return this.axiosIns.post(process.env.VUE_APP_BASEURL + this.jwtConfig.registerEndpoint, ...args)
    }
    ispRegistrationPay(...args) {
        return this.axiosIns.post(process.env.VUE_APP_BASEURL + this.jwtConfig.ispRegistrationPayEndpoint, ...args)
    }
    refreshToken() {
        return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
            refreshToken: this.getRefreshToken(),
        })
    }

    createRouter(...args) {
        return this.axiosIns.post(process.env.VUE_APP_BASEURL + ROUTER_CREATE, ...args)
    }

    updateRouter(rid, ...args) {
        return this.axiosIns.post(process.env.VUE_APP_BASEURL + ROUTER_UPDATE + rid, ...args)
    }

    createPackage(...args) {
        return this.axiosIns.post(process.env.VUE_APP_BASEURL + PACKAGE_CREATE, ...args)
    }

    updatePackage(rid, ...args) {
        return this.axiosIns.post(process.env.VUE_APP_BASEURL + PACKAGE_UPDATE + rid, ...args)
    }

    createCustomerUserName(...args) {
        return this.axiosIns.post(process.env.VUE_APP_BASEURL + USERNAME_CREATE, ...args)
    }

    updateCustomerUserName(uid, ...args) {
        return this.axiosIns.post(process.env.VUE_APP_BASEURL + USERNAME_UPDATE + uid, ...args)
    }
    deleteCustomerUserName(...args) {
        return this.axiosIns.post(process.env.VUE_APP_BASEURL + USERNAME_DELETE, ...args)
    }

    updateIspProfileInfo(...args) {
        return this.axiosIns.post(process.env.VUE_APP_BASEURL + ISP_PROFILE_UPDATE, ...args)
    }
    updateIspMobileBankingInfo(...args) {
        return this.axiosIns.post(process.env.VUE_APP_BASEURL + MOBILE_BAKING_INFO_UPDATE, ...args)
    }
    changePasswordIsp(...args) {
        return this.axiosIns.post(process.env.VUE_APP_BASEURL + ISP_CHANGE_PASSWORD, ...args)
    }
    invoiceEditIsp(...args) {
        return this.axiosIns.post(process.env.VUE_APP_BASEURL + INVOICE_EDIT, ...args)
    }
    invoicePaymentIsp(...args) {
        return this.axiosIns.post(process.env.VUE_APP_BASEURL + INVOICE_PAYMENT, ...args)
    }
    createNewInvoice(uid, ...args) {
        return this.axiosIns.post(process.env.VUE_APP_BASEURL + INVOICE_CREATE + uid, ...args)
    }

    deleteInvoiceById(...args) {
        return this.axiosIns.post(process.env.VUE_APP_BASEURL + INVOICE_DELETE, ...args)
    }

    newSettlementAdd(...args) {
        return this.axiosIns.post(process.env.VUE_APP_BASEURL + NEW_SETTLEMENT, ...args)
    }
    updateSettlement(...args) {
        return this.axiosIns.post(process.env.VUE_APP_BASEURL + SETTLEMENT_UPDATE, ...args)
    }
    addOrUpdatePermission(...args) {
        return this.axiosIns.post(process.env.VUE_APP_BASEURL + ADMIN_PERMISSION_STORE, ...args)
    }
    addRoleWisePermission(...args) {
        return this.axiosIns.post(process.env.VUE_APP_BASEURL + ADMIN_ROLE_STORE, ...args)
    }
    updateRoleWisePermission(roleId,...args) {
        return this.axiosIns.post(process.env.VUE_APP_BASEURL + ADMIN_ROLE_UPDATE + roleId, ...args)
    }
    addUserRoleWise(...args) {
        return this.axiosIns.post(process.env.VUE_APP_BASEURL + ADMIN_USER_STORE, ...args)
    }

    userChangePasswordISP(...args) {
        return this.axiosIns.post(process.env.VUE_APP_BASEURL + USER_CHANGE_PASSWORD_ISP, ...args)
    }
    bulkPaidInvoice(...args){
        return this.axiosIns.post(process.env.VUE_APP_BASEURL + BULK_INVOICE_PAID,...args)
    }
    bulkUnpaidInvoice(...args){
        return this.axiosIns.post(process.env.VUE_APP_BASEURL + BULK_INVOICE_UNPAID,...args)
    }
    ispServicePackagePay(...args) {
        return this.axiosIns.post(process.env.VUE_APP_BASEURL + SERVICES_PACKAGE_PAY, ...args)
    }
    createServicePackageAdmin(...args) {
        return this.axiosIns.post(process.env.VUE_APP_BASEURL + ADMIN_SERVICES_PACKAGE_CREATE_UPDATE, ...args)
    }
    createCouponCodeAdmin(...args) {
        return this.axiosIns.post(process.env.VUE_APP_BASEURL + ADMIN_COUPON_CODE_CREATE_UPDATE, ...args)
    }
}
